<template>
	<div>
		<h1>Planning opération paquets cadeaux</h1>
		<div id="banner">
			<p>🎁 Le stand aura lieu devant le magasin Nature et Découvertes, 9 rue des Merciers en centre-ville (<a target="_blank" href="https://www.google.com/maps/place/Nature+et+D%C3%A9couvertes+La+Rochelle/@46.1599616,-1.1532778,17z/data=!3m1!4b1!4m16!1m9!4m8!1m0!1m6!1m2!1s0x480153af2d1ae2ff:0x6911e5bdec62db5c!2snature+et+d%C3%A9couvertes+la+rocyhelle!2m2!1d-1.1506042!2d46.1599276!3m5!1s0x480153af2d1ae2ff:0x6911e5bdec62db5c!8m2!3d46.1599579!4d-1.1507029!16s%2Fg%2F113ht399k?hl=fr&entry=ttu">voir sur Google Maps</a>)</p>
			<p>Nous avons besoin d'au moins un bénévole pour chaque créneau, et idéalement deux à trois bénévoles les après-midi/soirs pour la troisième semaine.</p>
			<p>Pour vous inscrire à un créneau, contactez Noémie Sachot à sachot.noemie@gmail.com ou par SMS/appel au <a href="tel:0636828057">06-36-82-80-57</a>.
				Il est tout à fait possible d'ajouter des créneaux de 2h, discutons-en !</p>
			<p>Merci ♥️</p>
		</div>

		<h2>Première semaine</h2>
		<a href="#" class="accordeon-link" @click="show_week_one = !show_week_one">{{ link_show_week_one }}</a>
		<kalendar :configuration="calendar_settings_week_1" :events.sync="events" v-show="show_week_one">
			<!-- CREATED CARD SLOT -->
			<div
				slot="created-card"
				slot-scope="{ event_information }"
				:class="getCardClass(event_information.data.title)"
			>
				<h4 class="appointment-title">
					{{ event_information.data.title }}
				</h4>
				<small>
					{{ event_information.data.description }}
				</small>
				<span class="time"
					>{{ event_information.start_time | formatToHours }} -
					{{ event_information.end_time | formatToHours }}</span
				>
			</div>
		</kalendar>

		<hr>
		<h2>Deuxième semaine</h2>
		<a href="#" class="accordeon-link" @click="show_week_two = !show_week_two">{{ link_show_week_two }}</a>
		<kalendar :configuration="calendar_settings_week_2" :events.sync="events" v-show="show_week_two">
			<!-- CREATED CARD SLOT -->
			<div
				slot="created-card"
				slot-scope="{ event_information }"
				:class="getCardClass(event_information.data.title)"
			>
				<h4 class="appointment-title">
					{{ event_information.data.title }}
				</h4>
				<small>
					{{ event_information.data.description }}
				</small>
				<span class="time"
					>{{ event_information.start_time | formatToHours }} -
					{{ event_information.end_time | formatToHours }}</span
				>
			</div>
		</kalendar>

		<hr>
		<h2>Troisième semaine</h2>
		<kalendar :configuration="calendar_settings_week_3" :events.sync="events">
			<!-- CREATED CARD SLOT -->
			<div
				slot="created-card"
				slot-scope="{ event_information }"
				:class="getCardClass(event_information.data.title)"
			>
				<h4 class="appointment-title">
					{{ event_information.data.title }}
				</h4>
				<small>
					{{ event_information.data.description }}
				</small>
				<span class="time"
					>{{ event_information.start_time | formatToHours }} -
					{{ event_information.end_time | formatToHours }}</span
				>
			</div>
		</kalendar>
	</div>
</template>
<script>
const _existing_events = [
    /**
	 * Semaine 1
	 **/

	//Lundi 4
	{
		from: '2023-12-04T10:00:00+01:00',
		to:   '2023-12-04T13:00:00+01:00',
		data: {
			title: 'Géraldine Marty + Cathy',
		},
	},
	{
		from: '2023-12-04T10:00:00+01:00',
		to:   '2023-12-04T11:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	{
		from: '2023-12-04T13:00:00+01:00',
		to:   '2023-12-04T16:00:00+01:00',
		data: {
			title: 'Marie-Paule Gagnère',
		},
	},
	{
		from: '2023-12-04T16:00:00+01:00',
		to:   '2023-12-04T19:00:00+01:00',
		data: {
			title: 'Michèle Wendling',
		},
	},
	{
		from: '2023-12-04T16:00:00+01:00',
		to:   '2023-12-04T18:00:00+01:00',
		data: {
			title: 'Annie Lamour',
		},
	},
	{
		from: '2023-12-04T18:00:00+01:00',
		to:   '2023-12-04T19:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	//Mardi 5
	{
		from: '2023-12-05T10:00:00+01:00',
		to:   '2023-12-05T13:00:00+01:00',
		data: {
			title: 'Emma Allais',
		},
	},
	{
		from: '2023-12-05T10:00:00+01:00',
		to:   '2023-12-05T11:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	{
		from: '2023-12-05T13:00:00+01:00',
		to:   '2023-12-05T16:00:00+01:00',
		data: {
			title: 'Marie-Paule Gagnère',
		},
	},
	{
		from: '2023-12-05T13:00:00+01:00',
		to:   '2023-12-05T15:30:00+01:00',
		data: {
			title: 'Armelle Ridoux',
		},
	},
	{
		from: '2023-12-05T16:00:00+01:00',
		to:   '2023-12-05T18:00:00+01:00',
		data: {
			title: 'Annie Lamour',
		},
	},
	{
		from: '2023-12-05T15:30:00+01:00',
		to:   '2023-12-05T16:00:00+01:00',
		data: {
			title: 'Alexandra Molette',
		},
	},
	{
		from: '2023-12-05T16:00:00+01:00',
		to:   '2023-12-05T17:30:00+01:00',
		data: {
			title: 'Alexandra Molette',
		},
	},
	{
		from: '2023-12-05T17:30:00+01:00',
		to:   '2023-12-05T19:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	//Mercredi 6
	{
		from: '2023-12-06T10:00:00+01:00',
		to:   '2023-12-06T13:00:00+01:00',
		data: {
			title: 'Emma Allais',
		},
	},
	{
		from: '2023-12-06T10:00:00+01:00',
		to:   '2023-12-06T11:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	{
		from: '2023-12-06T13:00:00+01:00',
		to:   '2023-12-06T16:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	{
		from: '2023-12-06T16:00:00+01:00',
		to:   '2023-12-06T19:00:00+01:00',
		data: {
			title: 'Alexandra Molette',
		},
	},
	{
		from: '2023-12-06T16:00:00+01:00',
		to:   '2023-12-06T18:00:00+01:00',
		data: {
			title: 'Annie Lamour',
		},
	},
	{
		from: '2023-12-06T18:00:00+01:00',
		to:   '2023-12-06T19:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	//Jeudi 7
	{
		from: '2023-12-07T10:00:00+01:00',
		to:   '2023-12-07T13:00:00+01:00',
		data: {
			title: 'Emma Allais',
		},
	},
	{
		from: '2023-12-07T13:00:00+01:00',
		to:   '2023-12-07T16:00:00+01:00',
		data: {
			title: 'Elisabeth Horlaville',
		},
	},
	{
		from: '2023-12-07T13:00:00+01:00',
		to:   '2023-12-07T14:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	{
		from: '2023-12-07T16:00:00+01:00',
		to:   '2023-12-07T18:00:00+01:00',
		data: {
			title: 'Annie Lamour',
		},
	},
	{
		from: '2023-12-07T17:30:00+01:00',
		to:   '2023-12-07T18:00:00+01:00',
		data: {
			title: 'Thérèse Coleau',
		},
	},
	{
		from: '2023-12-07T18:00:00+01:00',
		to:   '2023-12-07T19:00:00+01:00',
		data: {
			title: 'Thérèse Coleau',
		},
	},
	{
		from: '2023-12-07T18:30:00+01:00',
		to:   '2023-12-07T19:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	//Vendredi 8
	{
		from: '2023-12-08T10:00:00+01:00',
		to:   '2023-12-08T13:00:00+01:00',
		data: {
			title: 'Emma Allais',
		},
	},
	{
		from: '2023-12-08T13:00:00+01:00',
		to:   '2023-12-08T16:00:00+01:00',
		data: {
			title: 'Elisabeth Horlaville',
		},
	},
	{
		from: '2023-12-08T12:00:00+01:00',
		to:   '2023-12-08T13:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	{
		from: '2023-12-08T16:00:00+01:00',
		to:   '2023-12-08T19:00:00+01:00',
		data: {
			title: 'Cathy',
		},
	},
	{
		from: '2023-12-08T18:30:00+01:00',
		to:   '2023-12-08T19:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	//Samedi 9
	{
		from: '2023-12-09T10:00:00+01:00',
		to:   '2023-12-09T13:00:00+01:00',
		data: {
			title: 'Laure Richard + Laurence Lamberty',
		},
	},
	{
		from: '2023-12-09T13:00:00+01:00',
		to:   '2023-12-09T16:00:00+01:00',
		data: {
			title: 'Alice Bernicot + 1 amie',
		},
	},
	{
		from: '2023-12-09T16:00:00+01:00',
		to:   '2023-12-09T19:00:00+01:00',
		data: {
			title: 'Michèle Wendling + Noémie Sachot',
		},
	},
	//Dimanche 10
	{
		from: '2023-12-10T10:00:00+01:00',
		to:   '2023-12-10T13:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	{
		from: '2023-12-10T13:00:00+01:00',
		to:   '2023-12-10T16:00:00+01:00',
		data: {
			title: 'Alice Bernicot + 1 amie',
		},
	},
	{
		from: '2023-12-10T16:00:00+01:00',
		to:   '2023-12-10T19:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	{
		from: '2023-12-10T17:15:00+01:00',
		to:   '2023-12-10T18:15:00+01:00',
		data: {
			title: 'Michèle Wendling',
		},
	},

	/**
	 * Semaine 2
	 **/

	//Lundi 11
	{
		from: '2023-12-11T10:00:00+01:00',
		to:   '2023-12-11T13:00:00+01:00',
		data: {
			title: 'Céline Drahonnet',
		},
	},
	{
		from: '2023-12-11T10:00:00+01:00',
		to:   '2023-12-11T11:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	{
		from: '2023-12-11T13:00:00+01:00',
		to:   '2023-12-11T14:30:00+01:00',
		data: {
			title: 'Léon Karlson + Carole',
		},
	},
	{
		from: '2023-12-11T14:30:00+01:00',
		to:   '2023-12-11T16:00:00+01:00',
		data: {
			title: 'Claire Denis + Michèle Wendling',
		},
	},
	{
		from: '2023-12-11T16:00:00+01:00',
		to:   '2023-12-11T19:00:00+01:00',
		data: {
			title: 'Céline Drahonnet',
		},
	},
	{
		from: '2023-12-11T16:00:00+01:00',
		to:   '2023-12-11T18:00:00+01:00',
		data: {
			title: 'Michèle Wendling',
		},
	},
	{
		from: '2023-12-11T18:00:00+01:00',
		to:   '2023-12-11T19:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	//Mardi 12
	{
		from: '2023-12-12T10:00:00+01:00',
		to:   '2023-12-12T13:00:00+01:00',
		data: {
			title: 'Viviane Rondel + Noémie Sachot',
		},
	},
	{
		from: '2023-12-12T13:00:00+01:00',
		to:   '2023-12-12T19:00:00+01:00',
		data: {
			title: 'Association Calmar',
		},
	},
	//Mercredi 13
	{
		from: '2023-12-13T10:00:00+01:00',
		to:   '2023-12-13T13:00:00+01:00',
		data: {
			title: 'Léon Karlson + Carole',
		},
	},
	{
		from: '2023-12-13T13:00:00+01:00',
		to:   '2023-12-13T16:00:00+01:00',
		data: {
			title: 'Isabelle VG',
		},
	},
	{
		from: '2023-12-13T13:00:00+01:00',
		to:   '2023-12-13T15:00:00+01:00',
		data: {
			title: 'Emma Allais',
		},
	},
	{
		from: '2023-12-13T15:00:00+01:00',
		to:   '2023-12-13T16:00:00+01:00',
		data: {
			title: 'Claire Denis',
		},
	},
	{
		from: '2023-12-13T16:30:00+01:00',
		to:   '2023-12-13T19:00:00+01:00',
		data: {
			title: 'Kassandra Aubry',
		},
	},
	{
		from: '2023-12-13T16:00:00+01:00',
		to:   '2023-12-13T19:00:00+01:00',
		data: {
			title: 'Amélie de Bee',
		},
	},
	//Jeudi 14
	{
		from: '2023-12-14T10:30:00+01:00',
		to:   '2023-12-14T11:30:00+01:00',
		data: {
			title: 'Michèle Wendling',
		},
	},
	{
		from: '2023-12-14T10:00:00+01:00',
		to:   '2023-12-14T10:45:00+01:00',
		data: {
			title: 'Claire Denis',
		},
	},
	{
		from: '2023-12-14T11:30:00+01:00',
		to:   '2023-12-14T13:30:00+01:00',
		data: {
			title: 'Léon Karlson + Carole',
		},
	},
	{
		from: '2023-12-14T13:30:00+01:00',
		to:   '2023-12-14T15:00:00+01:00',
		data: {
			title: 'Michèle Wendling',
		},
	},
	{
		from: '2023-12-14T14:30:00+01:00',
		to:   '2023-12-14T17:30:00+01:00',
		data: {
			title: 'Riwan Pain',
		},
	},
	{
		from: '2023-12-14T17:30:00+01:00',
		to:   '2023-12-14T19:00:00+01:00',
		data: {
			title: 'Thérèse Coleau',
		},
	},
	{
		from: '2023-12-14T17:30:00+01:00',
		to:   '2023-12-14T19:00:00+01:00',
		data: {
			title: 'Riwan Pain',
		},
	},
	//Vendredi 15
	{
		from: '2023-12-15T10:00:00+01:00',
		to:   '2023-12-15T13:00:00+01:00',
		data: {
			title: 'Monique Charriau + Noémie Sachot',
		},
	},
	{
		from: '2023-12-15T13:00:00+01:00',
		to:   '2023-12-15T19:00:00+01:00',
		data: {
			title: 'Association Calmar',
		},
	},
	//Samedi 16
	{
		from: '2023-12-16T10:00:00+01:00',
		to:   '2023-12-16T19:00:00+01:00',
		data: {
			title: 'Bernadette Perru',
		},
	},
	{
		from: '2023-12-16T10:00:00+01:00',
		to:   '2023-12-16T13:00:00+01:00',
		data: {
			title: 'Anna Gillardie',
		},
	},
	{
		from: '2023-12-16T13:00:00+01:00',
		to:   '2023-12-16T16:00:00+01:00',
		data: {
			title: 'Emilie Roche + Emma Allais',
		},
	},
	{
		from: '2023-12-16T16:00:00+01:00',
		to:   '2023-12-16T19:00:00+01:00',
		data: {
			title: 'Chantal Teyssier',
		},
	},
	//Dimanche 17
	{
		from: '2023-12-17T10:00:00+01:00',
		to:   '2023-12-17T13:00:00+01:00',
		data: {
			title: 'Association Calmar',
		},
	},
	{
		from: '2023-12-17T13:00:00+01:00',
		to:   '2023-12-17T19:00:00+01:00',
		data: {
			title: 'Bernadette Perru',
		},
	},
	{
		from: '2023-12-17T13:00:00+01:00',
		to:   '2023-12-17T17:00:00+01:00',
		data: {
			title: 'Michèle Wendling',
		},
	},
	{
		from: '2023-12-17T17:00:00+01:00',
		to:   '2023-12-17T19:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},

	/**
	 * Semaine 3
	 **/

	//Lundi 18
	{
		from: '2023-12-18T10:00:00+01:00',
		to:   '2023-12-18T13:00:00+01:00',
		data: {
			title: 'Solène Lamôle',
		},
	},
	{
		from: '2023-12-18T10:00:00+01:00',
		to:   '2023-12-18T11:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	{
		from: '2023-12-18T13:00:00+01:00',
		to:   '2023-12-18T16:00:00+01:00',
		data: {
			title: 'Marie-Paule Gagnère',
		},
	},
	{
		from: '2023-12-18T14:00:00+01:00',
		to:   '2023-12-18T17:00:00+01:00',
		data: {
			title: 'Amélie de Bee',
		},
	},
	{
		from: '2023-12-18T17:00:00+01:00',
		to:   '2023-12-18T19:00:00+01:00',
		data: {
			title: 'Cécile Dumont',
		},
	},
	//Mardi 19
	{
		from: '2023-12-19T10:00:00+01:00',
		to:   '2023-12-19T13:00:00+01:00',
		data: {
			title: 'Bernadette Perru',
		},
	},
	{
		from: '2023-12-19T13:00:00+01:00',
		to:   '2023-12-19T15:00:00+01:00',
		data: {
			title: 'Léon Karlson + Carole',
		},
	},
	{
		from: '2023-12-19T15:00:00+01:00',
		to:   '2023-12-19T19:00:00+01:00',
		data: {
			title: 'Chantal Teyssier',
		},
	},
	{
		from: '2023-12-19T16:00:00+01:00',
		to:   '2023-12-19T18:00:00+01:00',
		data: {
			title: 'Michèle Wendling',
		},
	},
	{
		from: '2023-12-19T18:00:00+01:00',
		to:   '2023-12-19T19:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	//Mercredi 20
	{
		from: '2023-12-20T10:00:00+01:00',
		to:   '2023-12-20T11:00:00+01:00',
		data: {
			title: 'Anne-Lise Drouet + Noémie Sachot',
		},
	},
	{
		from: '2023-12-20T11:00:00+01:00',
		to:   '2023-12-20T17:00:00+01:00',
		data: {
			title: 'Maïté Gloaguen',
		},
	},
	{
		from: '2023-12-20T11:00:00+01:00',
		to:   '2023-12-20T12:00:00+01:00',
		data: {
			title: 'Anne-Lise Drouet',
		},
	},
	{
		from: '2023-12-20T12:00:00+01:00',
		to:   '2023-12-20T14:00:00+01:00',
		data: {
			title: 'Lily Beau',
		},
	},
	{
		from: '2023-12-20T14:00:00+01:00',
		to:   '2023-12-20T17:00:00+01:00',
		data: {
			title: 'Riwan Pain',
		},
	},
	{
		from: '2023-12-20T17:00:00+01:00',
		to:   '2023-12-20T19:00:00+01:00',
		data: {
			title: 'Alexandra Molette',
		},
	},
	{
		from: '2023-12-20T17:00:00+01:00',
		to:   '2023-12-20T18:30:00+01:00',
		data: {
			title: 'Riwan Pain',
		},
	},
	{
		from: '2023-12-20T18:30:00+01:00',
		to:   '2023-12-20T19:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	//Jeudi 21
	{
		from: '2023-12-21T10:00:00+01:00',
		to:   '2023-12-21T12:00:00+01:00',
		data: {
			title: 'Michèle Wendling + Sylviane',
		},
	},
	{
		from: '2023-12-21T12:00:00+01:00',
		to:   '2023-12-21T15:00:00+01:00',
		data: {
			title: 'Riwan Pain',
		},
	},
	{
		from: '2023-12-21T12:00:00+01:00',
		to:   '2023-12-21T15:00:00+01:00',
		data: {
			title: 'Viviane Rondel',
		},
	},
	{
		from: '2023-12-21T15:00:00+01:00',
		to:   '2023-12-21T16:00:00+01:00',
		data: {
			title: 'Alexandra Molette',
		},
	},
	{
		from: '2023-12-21T16:00:00+01:00',
		to:   '2023-12-21T19:00:00+01:00',
		data: {
			title: 'Monique Charriau + Achille Anani',
		},
	},
	{
		from: '2023-12-21T16:00:00+01:00',
		to:   '2023-12-21T17:00:00+01:00',
		data: {
			title: 'Alexandra Molette',
		},
	},
	{
		from: '2023-12-21T18:00:00+01:00',
		to:   '2023-12-21T19:00:00+01:00',
		data: {
			title: 'Cécile Dumont',
		},
	},
	//Vendredi 22
	{
		from: '2023-12-22T10:00:00+01:00',
		to:   '2023-12-22T13:00:00+01:00',
		data: {
			title: 'Anne-Lise Drouet',
		},
	},
	{
		from: '2023-12-22T10:00:00+01:00',
		to:   '2023-12-22T12:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	{
		from: '2023-12-22T12:00:00+01:00',
		to:   '2023-12-22T13:00:00+01:00',
		data: {
			title: 'Viviane Rondel',
		},
	},
	{
		from: '2023-12-22T13:00:00+01:00',
		to:   '2023-12-22T16:00:00+01:00',
		data: {
			title: 'Marie-Paule Gagnère',
		},
	},
	{
		from: '2023-12-22T13:00:00+01:00',
		to:   '2023-12-22T15:00:00+01:00',
		data: {
			title: 'Viviane Rondel',
		},
	},
	{
		from: '2023-12-22T16:00:00+01:00',
		to:   '2023-12-22T19:00:00+01:00',
		data: {
			title: 'Riwan Pain + Rémi Lewandowski',
		},
	},
	//Samedi 23
	{
		from: '2023-12-23T10:00:00+01:00',
		to:   '2023-12-23T13:00:00+01:00',
		data: {
			title: 'Noémie Sachot',
		},
	},
	{
		from: '2023-12-23T11:30:00+01:00',
		to:   '2023-12-23T13:00:00+01:00',
		data: {
			title: 'Riwan Pain',
		},
	},
	{
		from: '2023-12-23T13:00:00+01:00',
		to:   '2023-12-23T19:00:00+01:00',
		data: {
			title: 'Fabienne Guibert',
		},
	},
	{
		from: '2023-12-23T12:00:00+01:00',
		to:   '2023-12-23T13:00:00+01:00',
		data: {
			title: 'Viviane Rondel',
		},
	},
	{
		from: '2023-12-23T13:00:00+01:00',
		to:   '2023-12-23T14:00:00+01:00',
		data: {
			title: 'Viviane Rondel',
		},
	},
	{
		from: '2023-12-23T14:00:00+01:00',
		to:   '2023-12-23T15:00:00+01:00',
		data: {
			title: 'Emilie Roche + Viviane Rondel',
		},
	},
	{
		from: '2023-12-23T15:00:00+01:00',
		to:   '2023-12-23T17:00:00+01:00',
		data: {
			title: 'Emilie Roche + Céline Drahonnet',
		},
	},
	{
		from: '2023-12-23T17:00:00+01:00',
		to:   '2023-12-23T19:00:00+01:00',
		data: {
			title: 'Riwan Pain + Céline Drahonnet',
		},
	},
	//Dimanche 24
	{
		from: '2023-12-24T10:00:00+01:00',
		to:   '2023-12-24T13:00:00+01:00',
		data: {
			title: 'Riwan Pain + famille Veillat',
		},
	},
	{
		from: '2023-12-24T13:00:00+01:00',
		to:   '2023-12-24T18:00:00+01:00',
		data: {
			title: 'Fabienne Guibert',
		},
	},
	{
		from: '2023-12-24T13:00:00+01:00',
		to:   '2023-12-24T16:00:00+01:00',
		data: {
			title: 'Riwan Pain + famille Veillat',
		},
	},
	{
		from: '2023-12-24T16:00:00+01:00',
		to:   '2023-12-24T18:00:00+01:00',
		data: {
			title: 'Viviane Rondel + Marie-Paule Gagnère',
		},
	},
];

const existing_events = _existing_events.map(ev => ({
	...ev,
	from: new Date(ev.from).toISOString(),
	to: new Date(ev.to).toISOString(),
}));

import Vue from 'vue';
import Kalendar from '@/lib-components/kalendar-container.vue';

export default {
	created() {
		Vue.filter('formatToHours', (value, how) => {
			let hour = new Date(value)
			hour.setHours(hour.getHours())
			return hour.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
		});
	},
	components: {
		Kalendar,
	},
	data() {
		return {
			show_week_one: false,
			show_week_two: false,
			events: existing_events,
			calendar_settings_week_1: {
				view_type: 'week',
				cell_height: 10,
				scrollToNow: false,
				hourlySelection: false,
				read_only: true,
				start_day: new Date('2023-12-04').toISOString(),
				day_starts_at: 9,
				day_ends_at: 20,
				overlap: true,
				hide_dates: [],
				hide_days: [],
				past_event_creation: true,
			},
			calendar_settings_week_2: {
				view_type: 'week',
				cell_height: 10,
				scrollToNow: false,
				hourlySelection: false,
				read_only: true,
				start_day: new Date('2023-12-11').toISOString(),
				day_starts_at: 9,
				day_ends_at: 20,
				overlap: true,
				hide_dates: [],
				hide_days: [],
				past_event_creation: true,
			},
			calendar_settings_week_3: {
				view_type: 'week',
				cell_height: 10,
				scrollToNow: false,
				hourlySelection: false,
				read_only: true,
				start_day: new Date('2023-12-18').toISOString(),
				day_starts_at: 9,
				day_ends_at: 20,
				overlap: true,
				hide_dates: [],
				hide_days: [],
				past_event_creation: true,
			},
		};
	},
	methods: {
		getCardClass: function(eventName) {
			console.log(eventName)
			switch (eventName) {
				case 'Disponible':
					return 'empty-card'
				case 'Association Calmar':
					return 'other-card'
				default:
					return 'details-card'
			}
		}
	},
	computed: {
		link_show_week_one: function() {
			return this.show_week_one ? "Cliquer pour masquer" : "Cliquer pour afficher"
		},
		link_show_week_two: function() {
			return this.show_week_two ? "Cliquer pour masquer" : "Cliquer pour afficher"
		}
	}
};
</script>
<style lang="scss">
$green: #00f0b5;
$red: #f61067;
$grey: #f4f0ec;
$darkgrey: #666666;

hr {
	color: $grey;
}

.accordeon-link {
	display: block;
}

#banner, h1, h2, .accordeon-link {
	text-align: center;
}

div.event-card.two-in-one .details-card > h4, div.event-card.three-in-one .details-card > h4 {
	font-size: 100%;
}

div.event-card .details-card > .time, div.event-card.two-in-one .details-card > .time, div.event-card.three-in-one .details-card > .time {
	font-size: 11px;
}

#banner {
	padding: 3em;
	background-color: $grey;
}

div.created-event > div.details-card {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	white-space: inherit;

	button {
		margin: 0;
		border: none;
		color: #4c4b4b;
		position: absolute;
		padding-right: 0px;
		top: 5px;
		right: 5px;
		cursor: pointer;
		background: transparent;

		svg {
			width: 18px;
			height: 18px;
			fill: white;
		}
	}

	.remove {
		opacity: 0;
		transition: opacity 0.15s;
	}

	&:hover .remove {
		opacity: 1;
	}
}

.empty-card {
	display: flex;
	flex-direction: column;
	width: 100px;
	height: 100%;

    background-color: $grey;
    width: 100%;
    color: black;
}

.other-card {
	display: flex;
	flex-direction: column;
	width: 100px;
	height: 100%;

    background-color: $darkgrey;
    width: 100%;
}

.is-past .created-event .other-card {
	color: #bcbcbc;
}

.created-event {
    padding: 0;
	margin-bottom: 2px;
	border-bottom: none !important;
}

.details-card, .empty-card, .other-card {
    padding: 4px;
}

.popup-event .buttons {
	display: flex;
	justify-content: space-between;
}

.popup-event .buttons button {
	border: none;
	color: #29771c;
	background-color: rgba($green, 0.04);
	padding: 5px 10px;

	&.cancel {
		background-color: rgba($red, 0.04);
		color: $red;
	}
}

.week-navigator {
	display: none;
}

.kalendar-wrapper {
	min-height: auto;
	min-width: 1100px;
}
</style>
